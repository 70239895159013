import { isEmpty, get, omit } from 'lodash';

const isImage = entity => entity.type === 'IMAGE';
const isVideoEmbed = entity => entity.type === 'VIDEO-EMBED';
const isLink = entity => entity.type === 'LINK';

const isEntityWithoutData = entity => (isImage(entity) || isVideoEmbed(entity)) && isEmpty(entity.data);
const isImageWithoutSrc = entity => isImage(entity) && !get(entity, 'data.src');
const isVideoWithoutUrl = entity => isVideoEmbed(entity) && !get(entity, 'data.url');
const isLinkWithoutUrl = entity => isLink(entity) && !get(entity, 'data.url');
const isCorrupted = entity => isEntityWithoutData(entity) || isImageWithoutSrc(entity) || isVideoWithoutUrl(entity);

const hasOneOfEntities = (block, entityKeys) => entityKeys.indexOf(get(block, 'entityRanges[0].key')) > -1;

const getCorrupted = entityMap =>
  Object.keys(entityMap).reduce(
    (acc, key) => {
      const entity = entityMap[key];
      if (isCorrupted(entity)) {
        acc.corruptedEntityKeys.push(Number(key));
      } else if (isLinkWithoutUrl(entity)) {
        acc.invalidLinkEntityKeys.push(Number(key));
      }
      return acc;
    },
    { corruptedEntityKeys: [], invalidLinkEntityKeys: [] },
  );

const removeCorrupted = (corruptedEntityKeys, content) => {
  const entityMap = content.entityMap;
  const isValidEntityRange = entityRange => {
    const entity = entityMap[entityRange.key];
    return entity && !isLinkWithoutUrl(entity);
  };
  return content.blocks.reduce((acc, block) => {
    if (hasOneOfEntities(block, corruptedEntityKeys)) {
      return acc;
    }
    if (Array.isArray(block.entityRanges) && block.entityRanges.length) {
      block.entityRanges = block.entityRanges.filter(isValidEntityRange);
    }
    acc.push(block);
    return acc;
  }, []);
};

/**
 * Removes invalid entities which were corrupted by bugs in wix-rich-content-editor
 */
export default function removeCorruptedEntities(content) {
  if (!content) {
    return content;
  }

  const { corruptedEntityKeys, invalidLinkEntityKeys } = getCorrupted(content.entityMap);
  return {
    ...content,
    blocks: removeCorrupted(corruptedEntityKeys, content),
    entityMap: omit(content.entityMap, [...corruptedEntityKeys, ...invalidLinkEntityKeys]),
  };
}
