import { includes } from 'lodash';
import { toSlug } from '@wix/communities-universal/dist/src/services/slug';
import { JAPANESE_PUNCTUATION_MARKS } from '@wix/communities-blog-universal/dist/src/constants/slug';
import { YEAR_SLASH_MONTH_OPTIONAL_SLASH_DAY } from '@wix/communities-blog-universal/dist/src/constants/regexp';
import { allowYearMonthDayPrefix as fixer } from '@wix/communities-blog-universal/dist/src/utils/slug-fixers';

const REGEXP_YEAR_MONTH_DAY = new RegExp(`^${YEAR_SLASH_MONTH_OPTIONAL_SLASH_DAY}/$`);

export const createSlug = (
  field,
  { blackListedSlugs, allowYearMonthDayPrefix, allowedCharacters = [], minLength, allowJapanesePunctuation } = {},
) => {
  const slug = toSlug({
    field,
    blackListedSlugs,
    allowedCharacters: allowJapanesePunctuation
      ? allowedCharacters.concat(JAPANESE_PUNCTUATION_MARKS)
      : allowedCharacters,
    minLength,
  });
  return allowYearMonthDayPrefix ? fixer(slug, { field }) : slug;
};

export const isOldSlug = (entity, slug) => !includes([entity._id, entity.slug], slug);

export const isValidAdvancedSlug = ({ year, month, day, postSlug }) => {
  if (!postSlug) {
    return false;
  }
  if (!day) {
    return REGEXP_YEAR_MONTH_DAY.test(`${year}/${month}/`);
  }
  return !isNaN(new Date(`${year}-${month}-${day}`).getTime());
};

export const resolvePostSlug = ({ year, month, day, postSlug } = {}) => {
  if (!isValidAdvancedSlug({ year, month, day, postSlug })) {
    return postSlug;
  }
  return day ? `${year}/${month}/${day}/${postSlug}` : `${year}/${month}/${postSlug}`;
};

export const resolveCategorySlug = ({ categorySlug, subcategorySlug } = {}) =>
  subcategorySlug ? `${categorySlug}/${subcategorySlug}` : categorySlug;

export const encodeURISlug = slug =>
  slug
    .split('/')
    .map(part => encodeURIComponent(part))
    .join('/');
