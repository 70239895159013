import { get } from 'lodash';
import { EXPERIMENT_DEFAULT_MOBILE_LAYOUT_SIDE_BY_SIDE } from '@wix/communities-blog-experiments';
import { getAppSettings } from './app-settings-selectors';
import { getLayoutTypePath } from '@wix/communities-blog-client-common/dist/src/constants/wix-params';
import {
  LAYOUT_SIDE_BY_SIDE,
  LAYOUT_FULL_POST,
  LAYOUT_ONE_COLUMN_MOBILE,
  LAYOUT_SIDE_BY_SIDE_MOBILE,
} from '@wix/communities-blog-client-common/dist/src/constants/layout-types';
import { isSeo, getIsMobile, getIsDesktop } from '../store/basic-params/basic-params-selectors';
import { getSection } from './section-selectors';
import {
  isExcludePostContentEnabled,
  isExperimentEnabled,
} from '@wix/communities-blog-client-common/dist/src/selectors/experiments-selectors';

// use withLayoutProps HOC for app

export const getLayoutType = (state, section) => {
  const isDefaultMobileLayoutSideBySideExperimentEnabled = isExperimentEnabled(
    state,
    EXPERIMENT_DEFAULT_MOBILE_LAYOUT_SIDE_BY_SIDE,
  );

  if (!section) {
    return undefined;
  }

  const defaultMobileLayout = isDefaultMobileLayoutSideBySideExperimentEnabled
    ? LAYOUT_SIDE_BY_SIDE_MOBILE
    : LAYOUT_ONE_COLUMN_MOBILE;

  if (getIsMobile(state)) {
    return get(getAppSettings(state), getLayoutTypePath(section, true), defaultMobileLayout);
  }

  return get(getAppSettings(state), getLayoutTypePath(section), LAYOUT_SIDE_BY_SIDE);
};

export const isMasonryLayoutSupported = state => !isSeo(state);

export const isFullPostLayout = (state, section) =>
  getLayoutType(state, section || getSection(state, true)) === LAYOUT_FULL_POST;

export const isRceRequired = (state, section) => getIsDesktop(state) && isFullPostLayout(state, section);

export const isExcludePostContentSupported = (state, section) =>
  isExcludePostContentEnabled(state) && !isRceRequired(state, section);
