import { isEmpty } from 'lodash';
import categoryService from '../../common/services/category';
import helpers from '../../common/services/helpers';
import { itemSelectors } from '../constants/category-list-widget';
import { blogPagePath } from '../../common/constants/paths';
import { EMPTY_STATE } from '../../common/constants/state-box-states';
import {
  getCategoryCharCount,
  getShowAllPostsCategory,
  getShowPostsNumber,
} from '../selectors/category-list-widget-selectors';

export const createCategoryListController = ({ $w, appParams, wixCodeApi, config }) => ({
  pageReady: () => {
    $w(itemSelectors.CATEGORY_LIST).onItemReady(($item, { label, postCount, categoryPageUrl }) => {
      const preparedTitle = helpers.applyCharLimitEllipsis(label, getCategoryCharCount(config));

      $item(itemSelectors.LIST_ITEM).label = `${preparedTitle} ${
        getShowPostsNumber(config) && postCount ? `(${postCount})` : ''
      }`;
      $item(itemSelectors.LIST_ITEM).onClick(() => {
        wixCodeApi.location.to(`${wixCodeApi.location.baseUrl}${blogPagePath}${categoryPageUrl}`);
      });
    });

    categoryService
      .getCategories({ instance: appParams.instance, wixCodeApi })
      .then(categories => {
        if (isEmpty(categories)) {
          $w(itemSelectors.STATE_BOX).changeState(EMPTY_STATE);
        } else {
          const preparedCategories = getShowAllPostsCategory(config)
            ? [
                {
                  _id: 'allcategories',
                  label: 'All Categories', // todo translate - waiting for OOI
                  postCount: categoryService.getAllPostsCount(categories),
                  categoryPageUrl: '',
                },
                ...categories,
              ]
            : categories;

          $w(itemSelectors.CATEGORY_LIST).data = preparedCategories;
        }
      })
      .then(() => $w(itemSelectors.MEDIA_BOX).show())
      .catch(console.error);
  },
});
