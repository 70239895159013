import { SECTION_HOMEPAGE, SECTION_CATEGORY } from '@wix/communities-blog-client-common/dist/src/constants/wix-params';
import { isInFeed, isInCategory } from '../services/detect-route';
import { getRoute } from '../router/router-selectors';

export const getSection = (state, fallbackEnabled) => {
  const route = getRoute(state);
  if (isInFeed(route)) {
    return SECTION_HOMEPAGE;
  }

  if (isInCategory(route)) {
    return SECTION_CATEGORY;
  }

  return fallbackEnabled ? SECTION_CATEGORY : undefined;
};
