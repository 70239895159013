import {
  POST_WIDGET_ID_PROD,
  POST_LIST_WIDGET_TYPE,
  POST_TICKER_WIDGET_TYPE,
  CATEGORY_LIST_WIDGET_TYPE,
  ARCHIVE_LIST_WIDGET_TYPE,
} from './constants/widgets';
import { createNoopController } from './controllers/createNoopController';
import { createOldPostPageController } from './controllers/createOldPostPageController';
import { createPostListController } from '../post-list-widget/controller';
import { createPostTickerController } from '../post-ticker-widget/controller';
import { createCategoryListController } from '../category-list-widget/controller';
import { createArchiveListController } from '../archive-list-widget/controller';

const controllerByType = {
  [POST_TICKER_WIDGET_TYPE]: createPostTickerController,
  [CATEGORY_LIST_WIDGET_TYPE]: createCategoryListController,
  [ARCHIVE_LIST_WIDGET_TYPE]: createArchiveListController,
  [POST_LIST_WIDGET_TYPE]: createPostListController,
  [POST_WIDGET_ID_PROD]: createOldPostPageController,
};

export const initAppForPage = () => Promise.resolve();

export const createControllers = controllerConfigs =>
  controllerConfigs.map(config => Promise.resolve((controllerByType[config.type] || createNoopController)(config)));

export const exports = {};
