import { get } from 'lodash';
import {
  DEFAULT_ANIMATION_AUTOPLAY,
  DEFAULT_CATEGORY,
  DEFAULT_FEATURED_POSTS,
  DEFAULT_POST_LIST_SIZE,
  DEFAULT_TIME_BETWEEN_IMAGES,
  DEFAULT_TITLE_CHAR_COUNT,
  propertyKeys,
} from '../constants/post-ticker-widget';
import { DEFAULT_POST_ORDER_TYPE } from '../../common/constants/post-widget';
import { DESKTOP } from '../../common/constants/widget-device-types';
import { getMainPanelProperties } from '../../common/selectors/config-selectors';

export const getFeaturedPostsEnabled = (config, deviceType = DESKTOP) =>
  get(getMainPanelProperties(config, deviceType), [propertyKeys.FEATURED_POSTS], DEFAULT_FEATURED_POSTS);

export const getPostListSize = (config, deviceType = DESKTOP) =>
  get(getMainPanelProperties(config, deviceType), [propertyKeys.POST_LIST_SIZE], DEFAULT_POST_LIST_SIZE);

export const getCategory = (config, deviceType = DESKTOP) =>
  get(getMainPanelProperties(config, deviceType), [propertyKeys.CATEGORY], DEFAULT_CATEGORY);

export const getTitleCharCount = (config, deviceType = DESKTOP) =>
  get(getMainPanelProperties(config, deviceType), [propertyKeys.TITLE_CHAR_COUNT], DEFAULT_TITLE_CHAR_COUNT);

export const getAnimationAutoplay = (config, deviceType = DESKTOP) =>
  get(getMainPanelProperties(config, deviceType), [propertyKeys.ANIMATION_AUTOPLAY], DEFAULT_ANIMATION_AUTOPLAY);

export const getTimeBetweenImages = (config, deviceType = DESKTOP) =>
  get(getMainPanelProperties(config, deviceType), [propertyKeys.TIME_BETWEEN_IMAGES], DEFAULT_TIME_BETWEEN_IMAGES);

export const getPostOrderType = (config, deviceType = DESKTOP) =>
  get(getMainPanelProperties(config, deviceType), [propertyKeys.POST_ORDER_TYPE], DEFAULT_POST_ORDER_TYPE);
  